import React from 'react';
import { Link } from 'gatsby';

const ExList = [
    {
        "title":"Inicio",
        "link":"/",
    },
    {
        "title":"Publicaciones",
        "link":"/publicaciones",
    },
    {
        "title":"Proyectos",
        "link":"/proyectos",
    },
    {
        "title":"Otros",
        "link":"/otros",
    },
]

const MenuNav = ({AnyList=ExList,title="Navegacion"}) => {
    return(
        <div className={"divide-y-2 divide-solid"}>
        <h1 className={"text-2xl"}>{title}</h1>
        <div>
        {AnyList.map((item)=>(
        <Link 
        className={"text-color1 py-3"}
        to={item.link} key={"menu"+item.title+item.link}><h1 className={"text-sm  my-4"}>{item.title}</h1></Link>
        ))}
        </div>
        </div>
    )
}

export default MenuNav;