import React from 'react';

const Threestages = ({leftNav=(<></>),content=(<></>),rightNav=(<></>)}) => {

    const Wrapper = (props={}) => {
        return (
            <div className={"w-full py-5 px-3 flex flex-col lg:flex-row xl:flex-row items-baseline justify-center lg:justify-around xl:justify-around content-center lg:divide-solid xl:divide-solid xl:divide-x-2 lg:divide-x-2"}>
                {props.children}
            </div>
        )
    }

    const LeftNavContainer = (props={}) => {
        return(
        <div className={"w-full lg:w-2/12 py-4 px-4"}>
            {props.children}
        </div>
        )
    }

    const RightNavContainer = (props={}) => {
        return (
        <div className={"w-full lg:w-2/12 py-4 px-4"}>
            {props.children}
        </div>
        )
    }

    const ContentContainer = (props={}) => {
        return (
        <div className={"w-full lg:w-8/12 py-4 px-4"}>
            {props.children}
        </div>
        )
    }


    return(
        <Wrapper>
           <LeftNavContainer>
               {leftNav}
           </LeftNavContainer>
           <ContentContainer>
               {content}
           </ContentContainer>
           <RightNavContainer>
               {rightNav}
            </RightNavContainer>
        </Wrapper>
    );
}

export default Threestages;