import * as React from "react";
import { GlobalStyle } from "../GlobalStyle";
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/Footer/Footer";
import { Helmet } from "react-helmet";
import ClickGallery from "../components/ClickGallery";
import Threestages from "../components/ThreeStages/Threestages";
import ColorDiv from "../components/ColorDiv/ColorDiv";
import PublicationList from "../components/PublicationList/PublicationList";
import AuthorPublications from "../components/AuthorPublications/AuthorPublications";
import AuthorList from "../components/AuthorList/AuthorList";
import MenuNav from "../components/MenuNav/MenuNav";
import SiteStructure from "../DataSources/SiteStructure";
import PannelGroup4 from "../components/PannelGroup/PannelGroup4";
import PannelGroup3 from "../components/PannelGroup/PannelGroup3";
import BlogPage from "../components/BlogPage/BlogPage";
import PannelGroup2 from "../components/PannelGroup/PannelGroup2";
// markup
const IndexPage = () => {
  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap" rel="stylesheet" />
      </Helmet>
      <GlobalStyle />
      <Navbar />

    


      <ColorDiv
        BgClass={"bg-color2 bg-opacity-25"}
        children={(
          <p>
            Anim dolor nisi eu reprehenderit adipisicing qui dolor sunt nulla.
            Minim eu aute aute nisi ullamco ullamco commodo pariatur ut qui labore ullamco elit ad.
            Consequat labore incididunt quis ad eu quis dolor sit ex nulla cillum aliquip eu deserunt.
            Lorem ad sint dolore occaecat qui fugiat sunt aute exercitation aute minim culpa sint qui.
          </p>
        )}
      />

      <PannelGroup2
        bgColorClass={"bg-white"}
        pannelColorClass={"bg-colorB"}
        content1={(<>
          Id aliquip cupidatat eu excepteur amet nulla quis anim ea culpa sint. Ex velit enim minim Lorem sit magna Lorem dolore. Anim est nostrud dolore deserunt labore aliqua esse enim deserunt. Commodo sunt aute velit officia quis incididunt proident ea Lorem laborum eiusmod ad. Nisi commodo amet nisi id dolore laboris anim. Dolore elit et nostrud non velit cupidatat esse. Ipsum laboris fugiat culpa ullamco eu.Et reprehenderit nostrud esse id ut est dolore nostrud amet laboris irure irure. Cillum do aliqua id incididunt tempor est aute qui duis cillum duis. Irure anim ut occaecat et.
        </>)}
        content2={(<>
          Velit laborum ad Lorem pariatur proident eu enim sunt. Non ea sunt deserunt esse cillum aliquip. Sit minim sint ea anim ullamco nisi.Exercitation voluptate nostrud ullamco adipisicing laboris ipsum exercitation velit aliquip amet sunt ad. Ad labore dolor duis dolore. Ut ea pariatur mollit dolore voluptate sit amet sunt dolor eu id. Non culpa magna irure amet.Minim exercitation do proident cillum enim sunt irure labore mollit aute duis excepteur tempor laborum. Sunt enim est quis voluptate ea aliqua aliquip. Fugiat nostrud mollit incididunt consequat adipisicing laborum magna et ea. Laboris enim duis enim sint consequat. Esse aliquip excepteur dolor do qui minim reprehenderit sint id magna cupidatat aliquip ullamco aute. Dolore enim deserunt exercitation proident elit reprehenderit consequat tempor cupidatat mollit. Ex anim laborum pariatur dolore nisi ex reprehenderit dolor nostrud commodo ut nulla commodo elit.
        </>)}
      />

      <Threestages
        content={(
          <PannelGroup2
            blog={true}
            bgColorClass={"bg-white"}
            pannelColorClass={"bg-colorB"}
            content1={(<>
              Id aliquip cupidatat eu excepteur amet nulla quis anim ea culpa sint. Ex velit enim minim Lorem sit magna Lorem dolore. Anim est nostrud dolore deserunt labore aliqua esse enim deserunt. Commodo sunt aute velit officia quis incididunt proident ea Lorem laborum eiusmod ad. Nisi commodo amet nisi id dolore laboris anim. Dolore elit et nostrud non velit cupidatat esse. Ipsum laboris fugiat culpa ullamco eu.Et reprehenderit nostrud esse id ut est dolore nostrud amet laboris irure irure. Cillum do aliqua id incididunt tempor est aute qui duis cillum duis. Irure anim ut occaecat et.
            </>)}
            content2={(<>
              Velit laborum ad Lorem pariatur proident eu enim sunt. Non ea sunt deserunt esse cillum aliquip. Sit minim sint ea anim ullamco nisi.Exercitation voluptate nostrud ullamco adipisicing laboris ipsum exercitation velit aliquip amet sunt ad. Ad labore dolor duis dolore. Ut ea pariatur mollit dolore voluptate sit amet sunt dolor eu id. Non culpa magna irure amet.Minim exercitation do proident cillum enim sunt irure labore mollit aute duis excepteur tempor laborum. Sunt enim est quis voluptate ea aliqua aliquip. Fugiat nostrud mollit incididunt consequat adipisicing laborum magna et ea. Laboris enim duis enim sint consequat. Esse aliquip excepteur dolor do qui minim reprehenderit sint id magna cupidatat aliquip ullamco aute. Dolore enim deserunt exercitation proident elit reprehenderit consequat tempor cupidatat mollit. Ex anim laborum pariatur dolore nisi ex reprehenderit dolor nostrud commodo ut nulla commodo elit.
            </>)}
          />
        )}
      />

      <PannelGroup3
        bgColorClass={"bg-white"}
        pannelColorClass={"bg-colorA"}
        content1={(<>
          Tempor non veniam aute esse do ad. Ullamco nostrud nostrud anim ullamco magna aliqua. Do officia esse officia nostrud officia cupidatat ipsum reprehenderit culpa. Non et excepteur irure ut occaecat voluptate elit consectetur veniam.Aliquip qui sit fugiat ad deserunt. Enim ex labore elit cupidatat sunt tempor tempor voluptate minim ad esse nisi. Reprehenderit officia magna laboris ipsum veniam et sit non laboris irure nulla ad labore.Aute sit ullamco exercitation eiusmod. Consectetur ad occaecat voluptate eu culpa. Sit non ad magna dolore laboris do. Duis eu incididunt ipsum ullamco duis cillum sint esse tempor eu fugiat amet esse voluptate. Cupidatat cupidatat sunt ipsum Lorem. Sit anim irure id eu pariatur. Eu aliquip irure do fugiat tempor voluptate culpa esse laboris ullamco enim adipisicing ex.
        </>)}
        content2={(<>
          Labore nostrud nisi exercitation qui qui reprehenderit esse elit in adipisicing consectetur veniam officia. Elit occaecat duis laborum labore fugiat ea. Elit occaecat id dolore fugiat.Consequat pariatur ad adipisicing nulla exercitation. Quis sint laborum culpa commodo proident incididunt tempor ea minim esse anim ex. Excepteur aliquip sint voluptate qui exercitation qui eiusmod amet commodo. Duis anim sunt aute sit nulla duis. Nostrud elit minim sint sunt eu. Eiusmod ut officia reprehenderit anim enim sunt aliqua non nostrud incididunt nulla laborum dolor minim. Pariatur et sint excepteur esse non.Magna amet amet excepteur do ullamco fugiat. Nulla labore cillum aute exercitation ea incididunt anim dolor consequat nostrud adipisicing anim. Veniam est tempor voluptate commodo ea aliqua voluptate pariatur irure incididunt nisi commodo eu. Nulla elit dolore laboris laboris officia. Anim deserunt do sit fugiat ut voluptate sint duis sit. Deserunt cillum magna labore consectetur Lorem occaecat aliquip incididunt cupidatat voluptate exercitation occaecat sit anim. Do ullamco ullamco cupidatat sunt reprehenderit aliqua id quis.
        </>)}
        content3={(<>
          Proident quis nisi proident fugiat exercitation dolore eu commodo proident dolore esse cupidatat quis. Consectetur voluptate pariatur nostrud cupidatat consequat exercitation mollit duis commodo exercitation. Id aliquip in mollit anim laborum tempor irure ullamco tempor ad eiusmod minim. Non duis occaecat sunt adipisicing labore. Excepteur reprehenderit eiusmod sit laborum ea ullamco laboris esse aliquip voluptate ex duis duis ipsum. Nulla do excepteur cillum tempor esse aute labore et occaecat minim. Cillum dolore in aliquip anim.Anim officia aute do velit adipisicing deserunt deserunt id voluptate tempor aute eiusmod. Elit ullamco cupidatat nisi voluptate anim magna ea ullamco laboris aute. Qui excepteur excepteur dolor ad esse anim fugiat elit voluptate amet culpa do proident.
        </>)}
      />

      <Threestages
        content={
          (
            <BlogPage
              title={"HOLA"}
              content={(
                <PannelGroup3
                  blog={true}
                  bgColorClass={"bg-white"}
                  pannelColorClass={"bg-colorA"}
                  content1={(<>
                    Tempor non veniam aute esse do ad. Ullamco nostrud nostrud anim ullamco magna aliqua. Do officia esse officia nostrud officia cupidatat ipsum reprehenderit culpa. Non et excepteur irure ut occaecat voluptate elit consectetur veniam.Aliquip qui sit fugiat ad deserunt. Enim ex labore elit cupidatat sunt tempor tempor voluptate minim ad esse nisi. Reprehenderit officia magna laboris ipsum veniam et sit non laboris irure nulla ad labore.Aute sit ullamco exercitation eiusmod. Consectetur ad occaecat voluptate eu culpa. Sit non ad magna dolore laboris do. Duis eu incididunt ipsum ullamco duis cillum sint esse tempor eu fugiat amet esse voluptate. Cupidatat cupidatat sunt ipsum Lorem. Sit anim irure id eu pariatur. Eu aliquip irure do fugiat tempor voluptate culpa esse laboris ullamco enim adipisicing ex.
                  </>)}
                  content2={(<>
                    Labore nostrud nisi exercitation qui qui reprehenderit esse elit in adipisicing consectetur veniam officia. Elit occaecat duis laborum labore fugiat ea. Elit occaecat id dolore fugiat.Consequat pariatur ad adipisicing nulla exercitation. Quis sint laborum culpa commodo proident incididunt tempor ea minim esse anim ex. Excepteur aliquip sint voluptate qui exercitation qui eiusmod amet commodo. Duis anim sunt aute sit nulla duis. Nostrud elit minim sint sunt eu. Eiusmod ut officia reprehenderit anim enim sunt aliqua non nostrud incididunt nulla laborum dolor minim. Pariatur et sint excepteur esse non.Magna amet amet excepteur do ullamco fugiat. Nulla labore cillum aute exercitation ea incididunt anim dolor consequat nostrud adipisicing anim. Veniam est tempor voluptate commodo ea aliqua voluptate pariatur irure incididunt nisi commodo eu. Nulla elit dolore laboris laboris officia. Anim deserunt do sit fugiat ut voluptate sint duis sit. Deserunt cillum magna labore consectetur Lorem occaecat aliquip incididunt cupidatat voluptate exercitation occaecat sit anim. Do ullamco ullamco cupidatat sunt reprehenderit aliqua id quis.
                  </>)}
                  content3={(<>
                    Proident quis nisi proident fugiat exercitation dolore eu commodo proident dolore esse cupidatat quis. Consectetur voluptate pariatur nostrud cupidatat consequat exercitation mollit duis commodo exercitation. Id aliquip in mollit anim laborum tempor irure ullamco tempor ad eiusmod minim. Non duis occaecat sunt adipisicing labore. Excepteur reprehenderit eiusmod sit laborum ea ullamco laboris esse aliquip voluptate ex duis duis ipsum. Nulla do excepteur cillum tempor esse aute labore et occaecat minim. Cillum dolore in aliquip anim.Anim officia aute do velit adipisicing deserunt deserunt id voluptate tempor aute eiusmod. Elit ullamco cupidatat nisi voluptate anim magna ea ullamco laboris aute. Qui excepteur excepteur dolor ad esse anim fugiat elit voluptate amet culpa do proident.
                  </>)}
                />
              )}
            />
          )
        }
      />


      <PannelGroup4
        bgColorClass={"bg-white"}
        pannelColorClass={"bg-colorA"}
        content1={(<>
          Ea commodo pariatur incididunt dolor commodo id. Est aliquip sint occaecat anim voluptate do nisi qui tempor. Tempor cupidatat eiusmod cupidatat adipisicing enim laborum enim. Commodo voluptate esse ex proident elit. Nostrud ullamco duis in laboris nulla ullamco amet mollit. Velit eu commodo veniam veniam elit voluptate occaecat occaecat Lorem. Cupidatat exercitation et adipisicing quis exercitation ex anim officia fugiat minim sunt et.Minim duis consequat dolor est Lorem in eiusmod aliqua. Consectetur ullamco proident consectetur non nostrud laborum tempor Lorem aliqua cillum veniam esse. Veniam anim fugiat cupidatat duis Lorem irure. Dolore nisi aliquip exercitation nisi duis consectetur qui pariatur dolore et. Do esse ea pariatur culpa ipsum anim labore labore ipsum aute.
        </>)}
        content2={(<>
          Elit reprehenderit adipisicing voluptate mollit fugiat labore sint aute exercitation deserunt qui nostrud culpa. Aute quis cupidatat Lorem velit labore mollit occaecat ea cupidatat aliqua nulla ipsum excepteur velit. Officia cupidatat id officia excepteur enim consectetur quis laborum. In excepteur do proident mollit. Adipisicing est irure proident sunt qui fugiat proident proident mollit. Dolore velit enim qui excepteur id duis duis mollit veniam.Dolor dolor sunt aute adipisicing laborum occaecat cillum ea esse. Nisi elit quis do aliquip Lorem commodo veniam do minim esse anim consequat proident. Quis enim occaecat velit est magna laboris sunt. Labore exercitation ad qui id tempor aliqua sint ea. Deserunt fugiat culpa irure pariatur qui. Et sit fugiat dolor et.Sit nisi pariatur Lorem laborum amet adipisicing. Amet adipisicing excepteur do ut esse velit ut tempor anim. Dolore officia dolore proident sint adipisicing quis.
        </>)}
        content3={(<>
          Ipsum aliquip deserunt sunt commodo velit et non elit occaecat ipsum. Aliqua in elit cillum mollit duis exercitation est nulla sit in. Pariatur amet voluptate ex eiusmod culpa labore occaecat cillum commodo veniam tempor do excepteur.Occaecat dolor exercitation officia esse consequat elit quis sit cillum. Eiusmod magna mollit non ad veniam exercitation. Consectetur minim fugiat fugiat sunt aliquip ad aliquip. Deserunt magna eiusmod occaecat ex cupidatat incididunt proident ipsum. Ullamco officia fugiat deserunt elit.Nulla nostrud reprehenderit pariatur dolor aliquip anim velit excepteur laboris dolor quis. Proident amet commodo ex deserunt Lorem dolore laborum. Dolor veniam reprehenderit Lorem do elit occaecat laboris cupidatat veniam veniam. Aute cupidatat magna enim anim et minim minim. Ut reprehenderit ullamco incididunt aute mollit.
        </>)}
        content4={(<>
          Quis laboris commodo proident ut ullamco quis esse ea mollit proident culpa eu in. Enim qui nulla nostrud pariatur nostrud non Lorem commodo. Mollit mollit ut in ut.Proident nisi ex elit commodo aute. Duis fugiat enim enim veniam sunt labore anim incididunt. Labore consequat sint aliquip veniam qui proident incididunt elit ex laborum deserunt incididunt nulla. Dolore aliqua non sunt officia ex commodo nisi ex velit in non sit voluptate. Fugiat ea ut sint aliquip ex ea culpa officia adipisicing elit esse aute eiusmod laboris. Velit ad occaecat ex sit aute anim elit reprehenderit eu quis et. Et ut Lorem non nulla tempor minim reprehenderit est id culpa occaecat ad Lorem magna.Occaecat exercitation aliqua culpa aute do exercitation adipisicing laboris tempor cillum fugiat id ad aliquip. Officia magna ad id officia. Lorem non incididunt velit Lorem veniam dolor laborum consequat mollit labore nostrud. Exercitation commodo esse et enim adipisicing sunt Lorem duis culpa consectetur pariatur aliqua. Cupidatat ex voluptate est deserunt dolor adipisicing ad laborum aute. Et do ipsum consequat qui.
        </>)}
      />

      <Threestages
        content={
          (
            <PannelGroup4
              blog={true}
              bgColorClass={"bg-white"}
              pannelColorClass={"bg-colorA"}
              content1={(<>
                Ea commodo pariatur incididunt dolor commodo id. Est aliquip sint occaecat anim voluptate do nisi qui tempor. Tempor cupidatat eiusmod cupidatat adipisicing enim laborum enim. Commodo voluptate esse ex proident elit. Nostrud ullamco duis in laboris nulla ullamco amet mollit. Velit eu commodo veniam veniam elit voluptate occaecat occaecat Lorem. Cupidatat exercitation et adipisicing quis exercitation ex anim officia fugiat minim sunt et.Minim duis consequat dolor est Lorem in eiusmod aliqua. Consectetur ullamco proident consectetur non nostrud laborum tempor Lorem aliqua cillum veniam esse. Veniam anim fugiat cupidatat duis Lorem irure. Dolore nisi aliquip exercitation nisi duis consectetur qui pariatur dolore et. Do esse ea pariatur culpa ipsum anim labore labore ipsum aute.
              </>)}
              content2={(<>
                Elit reprehenderit adipisicing voluptate mollit fugiat labore sint aute exercitation deserunt qui nostrud culpa. Aute quis cupidatat Lorem velit labore mollit occaecat ea cupidatat aliqua nulla ipsum excepteur velit. Officia cupidatat id officia excepteur enim consectetur quis laborum. In excepteur do proident mollit. Adipisicing est irure proident sunt qui fugiat proident proident mollit. Dolore velit enim qui excepteur id duis duis mollit veniam.Dolor dolor sunt aute adipisicing laborum occaecat cillum ea esse. Nisi elit quis do aliquip Lorem commodo veniam do minim esse anim consequat proident. Quis enim occaecat velit est magna laboris sunt. Labore exercitation ad qui id tempor aliqua sint ea. Deserunt fugiat culpa irure pariatur qui. Et sit fugiat dolor et.Sit nisi pariatur Lorem laborum amet adipisicing. Amet adipisicing excepteur do ut esse velit ut tempor anim. Dolore officia dolore proident sint adipisicing quis.
              </>)}
              content3={(<>
                Ipsum aliquip deserunt sunt commodo velit et non elit occaecat ipsum. Aliqua in elit cillum mollit duis exercitation est nulla sit in. Pariatur amet voluptate ex eiusmod culpa labore occaecat cillum commodo veniam tempor do excepteur.Occaecat dolor exercitation officia esse consequat elit quis sit cillum. Eiusmod magna mollit non ad veniam exercitation. Consectetur minim fugiat fugiat sunt aliquip ad aliquip. Deserunt magna eiusmod occaecat ex cupidatat incididunt proident ipsum. Ullamco officia fugiat deserunt elit.Nulla nostrud reprehenderit pariatur dolor aliquip anim velit excepteur laboris dolor quis. Proident amet commodo ex deserunt Lorem dolore laborum. Dolor veniam reprehenderit Lorem do elit occaecat laboris cupidatat veniam veniam. Aute cupidatat magna enim anim et minim minim. Ut reprehenderit ullamco incididunt aute mollit.
              </>)}
              content4={(<>
                Quis laboris commodo proident ut ullamco quis esse ea mollit proident culpa eu in. Enim qui nulla nostrud pariatur nostrud non Lorem commodo. Mollit mollit ut in ut.Proident nisi ex elit commodo aute. Duis fugiat enim enim veniam sunt labore anim incididunt. Labore consequat sint aliquip veniam qui proident incididunt elit ex laborum deserunt incididunt nulla. Dolore aliqua non sunt officia ex commodo nisi ex velit in non sit voluptate. Fugiat ea ut sint aliquip ex ea culpa officia adipisicing elit esse aute eiusmod laboris. Velit ad occaecat ex sit aute anim elit reprehenderit eu quis et. Et ut Lorem non nulla tempor minim reprehenderit est id culpa occaecat ad Lorem magna.Occaecat exercitation aliqua culpa aute do exercitation adipisicing laboris tempor cillum fugiat id ad aliquip. Officia magna ad id officia. Lorem non incididunt velit Lorem veniam dolor laborum consequat mollit labore nostrud. Exercitation commodo esse et enim adipisicing sunt Lorem duis culpa consectetur pariatur aliqua. Cupidatat ex voluptate est deserunt dolor adipisicing ad laborum aute. Et do ipsum consequat qui.
              </>)}
            />
          )
        }
      />


      <ClickGallery
        components={[
          ["Title 1", (
            <h1>Contenido1</h1>
          )]]}
        BgColor="colorA"
      />

      <ClickGallery
        components={[
          ["Title 1", (
            <h1>Contenido1</h1>
          )],
          ["Title 2", (
            <h2> Contenido 2</h2>
          )]]}
        BgColor="colorA"
      />

      <ClickGallery
        components={[
          ["Title 1", (
            <h1>Contenido1</h1>
          )],
          ["Title 2", (
            <h2> Contenido 2</h2>
          )],
          ["Title 3", (
            <h2> Contenido 3 </h2>
          )]]}
        BgColor="colorA"
      />

      <Threestages
        LeftNav={(
          <MenuNav
            title={SiteStructure[3]["title"]}
            AnyList={SiteStructure[3]["links"]}
          />
        )}
        Content={(
          <>
            <AuthorPublications authorData={{ "given": "Ezequiel", "family": "Garcia-Elorrio" }} />
            <PublicationList />
          </>
        )}
        RightNav={(
          <AuthorList />
        )}
      />

      <Footer />
    </>

  )
};

export default IndexPage
