import React from "react";

const ColorDiv = ({ children = <></>, BgClass = "bg-white" }) => {
    const Wrapper = (props = {}) => {
        return (
            <div className={BgClass + " " + "h-auto flex content-center justify-center items-center flex-wrap flex-col"}>
                {props.children}
            </div>
        )
    }

    const Content = (props = {}) => {
        return (
            <div className={"h-auto py-5 my-5 w-4/5 flex content-center justify-center items-center flex-wrap flex-col"}>
                {props.children}
            </div>
        )
    }

    return (
        <Wrapper>
            <Content>
                {children}
            </Content>
        </Wrapper>
    )
}

export default ColorDiv;