import React, { useState } from 'react';
import Publications from '../../DataSources/Publications';
import { Link } from 'gatsby';
import PublicationItem from '../PublicationItem/PublicationItem';
import BlogPage from '../BlogPage/BlogPage';





const PublicationList = () => {

    const [itemVisible, setItemVisible] = useState(-1);
    console.log(Publications)

    return(
        <BlogPage
        title={"Publicaciones"}
        content={(<>
            {Publications["data"].map((publication,i)=><PublicationItem order={i} Data={Publications["data"]} itemVisible={itemVisible} setItemVisible={setItemVisible}  key={"publications_page"+i}/>)}
        </>)}
        />
    )
}

export default PublicationList;