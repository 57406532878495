import React, { useState } from "react";


const ClickGallery = ({ components = [], BgColor = "white" }) => {

    const [Gallery, setGallery] = useState(1);

    const Wrapper = (props = {}) => {
        return (
            <div className={`w-full py-5 px-5 lg:px-40 xl:px-40 flex flex-col lg:flex-row xl:flex-row  flex-nowrap lg:flex-wrap xl-flex-wrap justify-center items-center content-center ${"bg-" + BgColor}`}>
                {props.children}
            </div>
        )
    }

    const Content = (props = {}) => {
        return (
            <div className="w-80 md:w-11/12 lg:w-11/12 xl:w-11/12 flex flex-col bg-white p-3 divide-y-2 divide-solid">
                {props.children}
            </div>
        )
    }

    const MenuContainer = (props = {}) => {
        return (
            <div className="w-full flex flex-col lg:flex-row xl:flex-row">
                {props.children}
            </div>
        )
    }

    const MenuItem = (props = {}) => {
        return (
            <div className={"w-full   bg-white px-4 py-2 border-opacity-100 text-center"}>
                {props.children}
            </div>
        )
    }

    const MenuItemOf2 = (props = {}) => {
        return (
            <div className={"w-full lg:w-1/2 xl:w-1/2  bg-white px-4 py-2 border-opacity-100 text-center"}>
                {props.children}
            </div>
        )
    }

    const MenuItemOf3 = (props = {}) => {
        return (
            <div className={"w-full lg:w-1/3 xl:w-1/3  bg-white px-4 py-2 border-opacity-100 text-center"}>
                {props.children}
            </div>
        )
    }

    const GalleryContent = (props = {}) => {
        return (
            <div className={"w-full p-3"}>
                {props.children}
            </div>
        )
    }


    {
        switch (components.length) {
            case 0:
                return null;
            case 1:
                return (
                    <Wrapper>
                        <Content>
                            <MenuContainer>
                                <MenuItem>
                                    <span>{components[0][0]}</span>
                                </MenuItem>
                            </MenuContainer>
                            <div className={"w-full p-3"}>
                                {components[0][1]}
                            </div>
                        </Content>
                    </Wrapper>
                );
            case 2:
                return (
                    <Wrapper>
                        <Content>
                            <MenuContainer>
                                <MenuItemOf2>
                                    <span className={Gallery === 1 ? "text-black" : "text-gray-300"} onClick={() => setGallery(1)}>
                                        {components[0][0]}
                                    </span>
                                </MenuItemOf2>
                                <MenuItemOf2>
                                    <span className={Gallery === 2 ? "text-black" : "text-gray-300"} onClick={() => setGallery(2)}>
                                        {components[1][0]}
                                    </span>
                                </MenuItemOf2>
                            </MenuContainer>
                            <GalleryContent>
                                {Gallery === 1 ? components[0][1] : null}
                                {Gallery === 2 ? components[1][1] : null}
                            </GalleryContent>

                        </Content>
                    </Wrapper>
                );
            case 3:
                return (
                    <Wrapper>
                        <Content>
                            <MenuContainer>
                                <MenuItemOf3>
                                    <span className={Gallery === 1 ? "text-black" : "text-gray-300"} onClick={() => setGallery(1)}>
                                        {components[0][0]}
                                    </span>
                                </MenuItemOf3>
                                <MenuItemOf3>
                                    <span className={Gallery === 2 ? "text-black" : "text-gray-300"} onClick={() => setGallery(2)}>
                                        {components[1][0]}
                                    </span>
                                </MenuItemOf3>
                                <MenuItemOf3>
                                    <span className={Gallery === 3 ? "text-black" : "text-gray-300"} onClick={() => setGallery(3)}>
                                        {components[2][0]}
                                    </span>
                                </MenuItemOf3>
                            </MenuContainer>
                            <GalleryContent>
                                {Gallery === 1 ? components[0][1] : null}
                                {Gallery === 2 ? components[1][1] : null}
                                {Gallery === 3 ? components[2][1] : null}
                            </GalleryContent>
                        </Content>
                    </Wrapper>
                );
            default:
                return null;
        }
    }
}

export default ClickGallery;