import React from 'react';

const Wrapper = (props) => {
    let ColorClass = props.bgColorClass ? props.bgColorClass + " " : "bg-transparent ";
    return <div className={ColorClass + "w-full py-10 px-1 lg:px-2 xl:px-2 2xl:px-2 flex flex-col lg:flex-row xl:flex-row 2xl:flex-row flex-wrap justify-around content-center"}>{props.children}</div>
}

const Pannel = (props) => {
    let ColorClass = props.bgColorClass ? props.bgColorClass + " " : "bg-transparent ";
    return <div className={ColorClass + "my-3 lg:mx-1 xl:mx-1 2xl:mx-1 w-11/12 lg:w-52 xl:w-72 py-1 px-1 flex flex-col justify-around content-center items-center"}>{props.children}</div>
}

const BlogWrapper = (props) => {
    let ColorClass = props.bgColorClass ? props.bgColorClass + " " : "bg-transparent ";
    return <div className={ColorClass + "w-full py-5 px-1 lg:px-1 xl:px-1 2xl:px-1 flex flex-col lg:flex-row xl:flex-row 2xl:flex-row flex-wrap justify-around content-center "}>{props.children}</div>
}

const BlogPannel = (props) => {
    let ColorClass = props.bgColorClass ? props.bgColorClass + " " : "bg-transparent ";
    return <div className={ColorClass + "my-4 lg:mx-1 xl:mx-1 2xl:mx-1 w-11/12 lg:w-36 xl:w-44 py-1 px-1 flex flex-col justify-around content-center items-center"}>{props.children}</div>
}


const PannelGroup4 = ({ blog = false, bgColorClass = "bg-transparent", pannelColorClass = "bg-transparent", content1 = (<></>), content2 = (<></>), content3 = (<></>), content4 = (<></>) }) => {

    if (blog) {
        return (
            <BlogWrapper bgColorClass={bgColorClass}>
                <BlogPannel bgColorClass={pannelColorClass}>
                    {content1 ? content1 : (<></>)}
                </BlogPannel>
                <BlogPannel bgColorClass={pannelColorClass}>
                    {content2 ? content2 : (<></>)}
                </BlogPannel>
                <BlogPannel bgColorClass={pannelColorClass}>
                    {content3 ? content3 : (<></>)}
                </BlogPannel>
                <BlogPannel bgColorClass={pannelColorClass}>
                    {content4 ? content4 : (<></>)}
                </BlogPannel>
            </BlogWrapper>
        )
    } else {
        return (
            <Wrapper bgColorClass={bgColorClass}>
                <Pannel bgColorClass={pannelColorClass}>
                    {content1 ? content1 : (<></>)}
                </Pannel>
                <Pannel bgColorClass={pannelColorClass}>
                    {content2 ? content2 : (<></>)}
                </Pannel>
                <Pannel bgColorClass={pannelColorClass}>
                    {content3 ? content3 : (<></>)}
                </Pannel>
                <Pannel bgColorClass={pannelColorClass}>
                    {content4 ? content4 : (<></>)}
                </Pannel>
            </Wrapper>
        )
    }
}

export default PannelGroup4;