import React from 'react';

const Wrapper = (props) => {
    let ColorClass = props.bgColorClass ? props.bgColorClass + " " : "bg-transparent ";
    return <div className={ColorClass + "w-full py-10 px-2 lg:px-4 xl:px-4 2xl:px-4 flex flex-col lg:flex-row xl:flex-row 2xl:flex-row flex-wrap justify-around content-center"}>{props.children}</div>
}

const Pannel = (props) => {
    let ColorClass = props.bgColorClass ? props.bgColorClass + " " : "bg-transparent ";
    return <div className={ColorClass + "my-1 lg:mx-3 xl:mx-3 2xl:mx-3 w-11/12 lg:w-72 xl:w-80  px-1 py-1 flex flex-col items-center"}>{props.children}</div>
}

const BlogWrapper = (props) => {
    let ColorClass = props.bgColorClass ? props.bgColorClass + " " : "bg-transparent ";
    return <div className={ColorClass + "w-full py-8 px-2  flex flex-col lg:flex-row xl:flex-row 2xl:flex-row flex-wrap justify-center content-center "}>{props.children}</div>
}

const BlogPannel = (props) => {
    let ColorClass = props.bgColorClass ? props.bgColorClass + " " : "bg-transparent ";
    return <div className={ColorClass + "my-1 lg:mx-3 xl:mx-3 2xl:mx-3 w-11/12 lg:w-44 xl:w-56  px-1 py-1 flex flex-col items-center"}>{props.children}</div>
}


const PannelGroup3 = ({ blog = false, bgColorClass = "bg-transparent", pannelColorClass = "bg-transparent", content1 = (<></>), content2 = (<></>), content3 = (<></>) }) => {

    if (blog) {
        return (
            <BlogWrapper bgColorClass={bgColorClass}>
                <BlogPannel bgColorClass={pannelColorClass}>
                    {content1 ? content1 : (<></>)}
                </BlogPannel>
                <BlogPannel bgColorClass={pannelColorClass}>
                    {content2 ? content2 : (<></>)}
                </BlogPannel>
                <BlogPannel bgColorClass={pannelColorClass}>
                    {content3 ? content3 : (<></>)}
                </BlogPannel>
            </BlogWrapper>
        )
    } else {
        return (
            <Wrapper bgColorClass={bgColorClass}>
                <Pannel bgColorClass={pannelColorClass}>
                    {content1 ? content1 : (<></>)}
                </Pannel>
                <Pannel bgColorClass={pannelColorClass}>
                    {content2 ? content2 : (<></>)}
                </Pannel>
                <Pannel bgColorClass={pannelColorClass}>
                    {content3 ? content3 : (<></>)}
                </Pannel>
            </Wrapper>
        )
    }
}

export default PannelGroup3;