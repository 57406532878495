import React from "react";

const BlogPage = ({title="",content=(<></>)}) => {
    return(
        <div className="w-full flex flex-col flex-nowrap justify-center center-items content-center divide-solid divide-y-2">
            <h1 className={"text-3xl my-1"}>{title}</h1>
            <div className={"my-1 py-1"}>
                {content}
            </div>
        </div>
    )
}
export default BlogPage;