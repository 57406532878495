const SiteStructure = [
    {
        "title":"Acerca de nosotros",
        "links":[
            {
                "title":"¿Que es QuEST?",
                "link":"/acerca/que-es"
            },
            {
                "title":"Objetivos",
                "link":"/acerca/objetivos",
                "submenu":{
                    "title":"Objetivos",
                    "links":[
                        {
                            "title":"Consolidarse como el capítulo latinoamericano de la Red QuEST",
                            "link":"/acerca/objetivos/objetivo1"
                        },
                        {
                            "title":"Promover el desarrollo de capacidades en investigación sobre sistemas de salud en la región de LAC y en los miembros de la Red.",
                            "link":"/acerca/objetivos/objetivo2"
                        },
                        {
                            "title":"Realizar investigación y desarrollo en la región para informar a los responsables políticos locales.",
                            "link":"/acerca/objetivos/objetivo3"
                        },
                    ]
                },
            },
            {
                "title":"Estructura",
                "link":"/acerca/estructura"
            },
            {
                "title":"Financiamiento",
                "link":"/acerca/financiamiento"
            },
            {
                "title":"Aliados globales",
                "link":"/acerca/partners"
            },
        ]
    },
    {
        "title":"Calidad en salud",
        "links":[
            {
                "title":"Definiciones",
                "link":"/calidadensalud/definiciones"
            },
            {
                "title":"Importancia",
                "link":"/calidadensalud/importancia"
            },
        ],
    },
    {
        "title":"Proyectos",
        "links":[
            {
                "title":"Proyectos en Marcha",
                "link":"/proyectos/enmarcha"
            },
            {
                "title":"Proyectos futuros",
                "link":"/proyectos/futuros"
            },
        ]
    },
    {
        "title":"Actividades",
        "links":[
        {
            "title":"Webinars",
            "link":"/actividades/webinars"
        },
        {
            "title":"Cursos Cortos",
            "link":"/actividades/cursos-cortos"
        },
        {
            "title":"Publicaciones",
            "link":"/actividades/publicaciones"
        },
        {
            "title":"Otras actividades",
            "link":"/actividades/otros"
        },
    ]
}    
]

export default SiteStructure;